<template>
  <div :class="['flex', 'w-full', `justify-${direction} my-2`]">
    <div :class="classes" style="max-width: 85%;">
      <h5 class="font-bold">{{title}}</h5>
      <p>
        {{message}}
      </p>
      <div class="text-right text-xs">{{time}}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    direction: {
      type: String,
      default: 'start'
    },
    title: String,
    time: String,
    message: String
  },
  computed: {
    classes () {
      const classes = 'shadow rounded px-4 py-2 '
      return classes + (
        this.direction === 'start' ? 'has-background-grey-light' : 'has-background-primary text-white'
      )
    }
  }
}
</script>

<style>

</style>
